<template>
  <div class="home">
    <img src="../assets/1.jpg" alt="" style="width: 100%;">
    <div class="order-wrapper" style="background-color: #F4B2FC;">
      <div class="order">

        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field v-model="username" name="用户名" label="您的姓名:" placeholder="请输入收货人姓名" />
            <van-field v-model="phoneNumber" maxlength="11" name="手机号" label="手机号码:" placeholder="请输入手机号"  type="number"/>

            <van-cell-group inset>
              <van-field v-model="authCode" center label="验证码" placeholder="请输入验证码" class="cc" type="number">
                <template #button>
                  <van-button size="small" type="primary" class="send" @click="sendCode"
                    style=" background-color: #fff;color: rgb(238, 10, 36);border: 1px solid #ecbcbc;width: 70px;padding: 0; height: 24px;font-size: 12px; line-height: 24px;">{{
                      yzm == 0 ? '发送验证码' : yzm }}</van-button>
                </template>
              </van-field>
            </van-cell-group>
            <van-field v-model="address" is-link readonly name="area" label="地区选择：" placeholder="点击选择省市区"
              @click="showArea = true" />
            <van-cell-group inset>
              <van-field v-model="message" rows="1" style="min-height: 80px;max-height: 80px;" label="详细地址："
                type="textarea" placeholder="如街道、小区门牌等详细地址" />
            </van-cell-group>
          </van-cell-group>
          <div class="checkBox">
            <van-checkbox v-model="checked" icon-size="14px" checked-color="#ee0a24" shape="square"></van-checkbox>
            <div style="margin-left: 8px;">我已阅读并同意</div>
            <div style="color:#3d94f6;" @click="showRu = true">《入网协议》</div>
            <div style="color:#3d94f6;" @click="showXin = true">《信息手机公告》</div>
          </div>
          <div style="margin: 16px; font-size: 40px;">
            <van-button round block type="danger" color="rgb(255, 0, 0)" native-type="submit">
              <div style="font-size: 17px;font-weight: 700;"> 免费领取-顺丰包邮 </div>
            </van-button>
          </div>
        </van-form>

      </div>
    </div>
    <img src="../assets/2.jpg" alt="" style="width: 100%;">
    <!-- 地区选择器 -->
    <van-popup v-model:show="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
    </van-popup>
    <!-- 入网协议 -->
    <van-popup v-model:show="showRu" position="bottom" :style="{ height: '300px' }">
      <div class="agreement-wrapper">
        <div class="title">中国网络通信客户服务协议</div>
        <p>甲方：中国网络通信客户 乙方：中国网络通信集团</p>
        <p> 甲方所需的业务、办理手续以及资费标准请参见乙方的相关业务说明。
          根据《中华人民共和国合同法》、《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在平等、自愿、公平、诚实、信用的基础上，就电话网络服务的有关事宜，达成协议如下：</p>
        <p> 一、服务内容和资费标准 1、乙方在其电话网络覆盖范围内，为甲方有偿提供通信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商网络覆盖范围内为甲方提供国际漫游服务。 2、甲方可自主选择乙方提供的各项通信业务。
          3、乙方根据乙方计费系统数据向甲方收取相关费用，包括月基本费、通话费、短信（含彩信）费以及甲方申请的其他业务的费用。计费周期为自然月，即每月第一日至当月最后一日,由于网络设备产生话单及相关处理会有时延，可能发生每月部分话费计入下月话费中收取的情况，届时乙方将在话费详单中明确显示。乙方资费方案、计费规则、收取方式等如有调整，将提前公告。
          4、如甲方通过乙方的通信网络定制、收发与乙方有合作关系的信息服务提供商所提供的各类信息，甲方同意乙方根据信息服务提供商的授权，向甲方代为收取信息费。 </p>
        <p> 二、乙方的权利与义务 1、乙方为甲方提供的通信服务应当达到国家的相关标准。 2、乙方通过营业厅、网站及短信等方式向甲方公布并提示服务项目、服务时限、服务范围及资费标准等内容。
          3、乙方应采取下列一种或数种方式向甲方提供方便的业务办理、业务咨询和话费信息查询渠道，包括营业厅、服务热线、服务网站、短信及其他方式等。 4、乙方有义务采取公布监督电话等形式受理甲方投诉，并在接到投诉之日起15日内答复甲方。
          5、乙方对电话原始话费数据及信息服务计费原始数据保留期限为5个月(系统产生用户话单当月起后5个月，不含当月)，对用户提出异议的话单，应保存至异议解决为止。
          6、若甲方对乙方收取的话费存有异议，乙方有责任进行调查、解释，并告知甲方核实处理的结果。
          7、乙方严格遵守国家法律法规关于用户个人信息保护、网络安全等规定。乙方收集、使用甲方个人信息，应当遵循合法、正当、必要的原则。甲方理解并同意，乙方及其关联公司可以通过业务受理系统登记、纸质返档，通过网络接收、读取并记录等方式，以提供电信服务为目的，在业务活动中收集、使用甲方提供的和甲方使用服务过程中形成的个人信息。乙方有权依法对包含甲方在内的整体用户数据进行分析并加以利用。未经甲方同意，乙方不向除乙方关联公司外的第三方提供甲方个人信息。甲方可以通过营业厅或乙方指定的其他渠道，对其个人信息进行查询、更正。乙方不得侵害甲方的通信自由和通信秘密，对甲方的客户资料负有保密义务。但根据法律法规规定，乙方有义务配合法定机关对客户通信、资料、数据的调查取证等工作。
          8、乙方应免费向甲方提供通话所在地（仅限国内）火警119、匪警110、医疗急救120、交通事故报警122等公益性电话接入服务。
          9、在甲方欠费的情况下，如甲方在约定的期限内补齐费用并申请开机的，乙方在收到甲方费用时起24个小时内为甲方恢复通信服务。
          10、乙方对甲方暂停通信服务时(以下简称停机)，对使用“先使用，后付费”缴费方式的甲方应提前通知；对使用“先预存话费，后使用”缴费方式的甲方，乙方应采用短信、电话等方式进行余额提示。
          11、乙方检修线路、设备搬迁、工程割接、网络及软件升级等可预见的原因可能影响甲方使用的，应提前72小时通知甲方，乙方应采用下列一种或数种方式，即短信、电话、广播、电视、信函、公告、报纸、互联网等方式进行业务公告或通知。
          12、甲方向乙方申告电话通信障碍（指交换设备或传输线路原因造成通信中断等现象，不包括网络覆盖和终端设备故障），乙方应按《电信条例》的规定处理。
          13、乙方应在承诺时限内为甲方开通其申请的服务(双方约定超出此时限的除外)，乙方未及时开通的，应免除甲方自申请之日至服务开通期间的该项服务的月功能费。此外造成甲方直接损失的，乙方依法承担违约责任。
          14、乙方承诺自身不利用通信网络向甲方发送违法信息或骚扰信息（不包括乙方为甲方提供业务服务而发送的信息）。 </p>
        <p> 三、甲方的权利与义务
          1、甲方办理入网手续时，需向乙方提供真实有效的身份证件原件，代办入网时，需同时提供代理人有效身份证件原件及授权委托书。个人客户有效证件包括居民身份证、临时居民身份证或户口簿；中国人民解放军军人、武装警察身份证件；港澳居民往来内地通行证、台湾居民往来大陆通行证或者其他有效旅行证件；外国公民护照。单位客户有效证件包括统一社会信用代码证书、营业执照副本、组织机构代码证等有效证件原件或加盖单位公章的有效证件的复印件。以单位证件办理入网登记的，除出示单位有效证件之一外，还应当出示经办人和使用人的有效证件及授权委托书。如甲方及其受托人拒绝出示有效证件，拒绝提供其证件上所记载的身份信息，乙方有权不为其办理入网手续。如甲方入网登记资料发生变更，应及时通知乙方。
          甲方应当按照约定的时间和方式及时、足额地向乙方交纳电信费用；甲方逾期不交纳电信费用的，乙方有权要求补交电信费用，并可以按照所欠费用每日加收3‰的违约金。
          3、若甲方对乙方收取的通信费及代信息服务商收取的信息费存有异议，应在异议话费发生后五个月内向乙方提出(系统产生用户话单当月起后5个月，不含当月)，乙方应将有争议的数据保存到争议解决为止。
          4、甲方要求终止提供通信服务时，应在结清所有费用后办理退网手续。 5、甲方承诺不利用通信网络发送违法信息或对其他客户的骚扰信息，甲方向第三方发送广告等信息，需经第三方同意认可。 </p>
        <p> 四、特殊情况的责任承担 1、在下列情况下，乙方有权暂停或限制甲方的通信服务，由此给甲方造成的损失，乙方不承担责任： （1）甲方银行帐户被查封、冻结或余额不足等非乙方原因造成乙方结算时划扣不成功的；
          （2）甲方预付费使用完毕而未及时补交款项（包括预付费账户余额不足以划扣下一笔预付费用）的； （3）甲方使用“先使用，后付费”的费用结算方式时，电话费用超过可透支额度的； （4）甲方发送带有非法内容信息的。
          2、乙方一经发现甲方突然出现超过自己此前三个月平均通信费用5倍以上通信费用时，应当尽可能迅速告知甲方，乙方可以暂停或限制甲方的通信服务，并采取相应的措施。由此给甲方造成的损失，乙方不承担责任。
          3、甲方如出现违法使用、违规外呼、呼叫频次异常、超约定用途使用、转租转售、发送违法违规信息、未经接收客户同意发送商业广告信息给他人造成骚扰、被公安机关通报以及用户就上述问题投诉较多等情况的，经乙方核实确认后，乙方有权终止业务接入。因此给甲方造成的损失，乙方不承担责任。
          4、甲方名下的电话号码逾期未交清费用的，乙方有权拒绝甲方以自己名义提出的其他通信业务申请（包括开办新的电话卡），缴费、话费查询除外。根据国家相关规定，甲方办理有效使用的电话卡达到5张的，乙方不予为其开办新的电话卡。
          5、在甲方欠费时，乙方可采取信函、委托第三方等形式追缴欠费，并可按照有关规定向征信机构提供甲方的欠费信息。
          6、甲方入网后即获取客户服务密码，甲方应妥善保管。服务密码是业务办理的凭证。凡使用服务密码定制、变更业务的行为均被视为甲方或甲方授权的行为。为方便办理业务，乙方也可根据甲方申请以短信等方式提供随机服务密码，该密码可作为办理业务的临时凭证。
          7、因甲方保管不善等原因导致其电话被他人非法使用，甲方应尽可能迅速告知乙方，办理停机或补卡手续，乙方应予配合并依法采取相应措施。甲方可向公安部门报案，乙方应在技术上协助公安部门进行调查。除乙方有过错外，乙方不承担相应责任。
          8、因甲方（包括代理人）提供的客户资料不详、不实或变更后未通知乙方等原因，使乙方无法将服务（如话费单据等）提供给甲方，乙方不承担由此对甲方所造成的不良后果。甲方（包括代理人）未完全履行本协议约定义务，所提供客户资料不真实、不准确、不完整或无效的，乙方有权暂时中止履行本协议并催告甲方（包括代理人）补充提供相关资料。甲方在接到乙方催告后30天内拒不补充提供到位的，乙方有权终止本协议。
          9、当不可抗力的情形发生时，根据不可抗力的影响，部分或者全部免除责任，但法律另有规定的除外。甲方或乙方迟延履行后发生不可抗力的，不能免除责任。 10、一方违约给对方造成损失的，应当依法承担赔偿责任。 </p>
        <p> 五、协议的变更、转让与终止
          1、甲方办理各类业务所签署的表单、乙方以公告等书面形式公开做出的服务承诺均为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分以本协议为准。但补充协议不得规避和降低乙方的法定义务和服务标准。同时不一致条款应向客户明示（如字体加黑、划线或变换字体等）。
          2、乙方承诺资费方案有效期为一年（双方有特殊约定的除外）。乙方有权在有效期截止后修改资费方案。如需修改，乙方应在有效期届满前两个月通知甲方。如无需修改，则原资费方案顺延一年，顺延次数不限。如甲方不接受修改，则甲方可到期取消该资费方案。
          3、如甲方要将协议项下的手机号码及附属权利义务转让给第三人（过户），应与第三人共同到乙方营业网点协商，在三方就权利义务转移等问题达成一致后，共同签订过户协议，本协议同时自动终止。因甲方私自转让而造成的欠费、停机等后果，由甲方自行承担。
          4、因技术进步，乙方为提升服务质量对电话网络进行整体换代升级而导致通信服务无法继续履行的，乙方应至少提前90日以短信或者公告的方式告知甲方，并提供合理的解决方案。
          5、下列情况下乙方有权解除协议，收回号码，终止提供服务。由此给甲方造成的损失，乙方不承担责任，并有权向甲方追讨欠费： （1）电话被用于违法犯罪活动，或甲方严重违约致使乙方遭受较大损失；
          （2）乙方收到法定机关要求停止为甲方提供通信服务； （3）甲方欠费停机超过60天。 6、在法定终止条件或约定终止条件具备时，本协议终止。乙方继续保留向甲方追缴所欠费用的权利，有权收回号码，并在服务规范规定的冷号期后重新启用。
        </p>
        <p> 六、其他约定 1、信息服务提供商通过乙方的网络平台提供的增值电信应用服务，甲方可自愿订购或退订。
          2、甲方使用信息服务提供商提供的增值电信应用服务的资费标准由信息服务提供商公布。若甲方对收取的服务费用有异议，乙方与信息服务提供商应遵循“首问负责”的原则，共同协商处理。
          3、乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
          4、双方的联系方式位于签章处，一方如有变更，应及时通知另一方，未及时通知的，一方按照原有地址发送文件的（包括但不限于欠费催缴通知）发出后即视为送达。
          5、本协议项下发生的争议，双方可协商解决，协商不成的，双方可选择向电信管理部门申诉，或向消费者协会投诉，或按下列方式解决： 向本地人民法院提起诉讼。 6、本协议双方签字、盖章后生效。对本协议未尽事宜，双方协商解决。 </p>
        <p>甲方 ：（签名或单位盖章） 乙方：（合同专用章）</p>
        <p>甲方监护人或代理人：（签名或单位盖章） 地址：_________________________________</p>
        <p>地址：___________________________</p>
        <p>甲方号码：___________________</p>
        <p>签署日期：________年____月____日</p>
      </div>
    </van-popup>
    <!-- 信息收集公告 -->
    <van-popup v-model:show="showXin" position="bottom" :style="{ height: '300px' }">
      <div class="agreement-wrapper">
        <div class="title">信息收集公告</div>
        <p>尊敬的客户：</p>
        <p>
          根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
        </p>
        <p>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</p>
        <p>
          为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
        </p>
        <p>(a)事先获得客户的明确授权；</p>
        <p>(b)根据有关的法律法规要求；</p>
        <p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
        <p>(d)为维护社会公众的利益所必需且适当；</p>
        <p>(e)为维护我公司或客户的合法权益所必需且适当。</p>

      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import { areaList } from '@vant/area-data'
import { showToast } from 'vant'
import { getCode, submit } from '../request/api'
export default {
  name: 'HomeView',
  components: {

  },
  data () {
    return {
      username: '',
      phoneNumber: '',
      authCode: '',
      showArea: false,
      areaList,
      address: '',
      showPicker: false,
      message: '',
      checked: false,
      showRu: false,
      showXin: false,
      yzm: '0',
      value: '',
      addressList: []
    }
  },
  created () {
    document.title = '网上营业厅'
  },
  methods: {
    sendCode () {
      // eslint-disable-next-line eqeqeq, no-empty
      if (this.phoneNumber.length == 0) {
        showToast('请输入手机号')
      // eslint-disable-next-line eqeqeq
      } else if (this.phoneNumber.length != 11 && this.phoneNumber.length != 0) {
        showToast('手机号格式错误')
        // eslint-disable-next-line no-empty
      } else {
        const re = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/
        const str = this.phoneNumber
        if (re.test(str)) {
          // eslint-disable-next-line eqeqeq
          if (this.yzm == 0) {
            this.yzm = 60
            // eslint-disable-next-line no-var, camelcase
            var auth_timetimer = setInterval(() => {
              this.yzm--
              if (this.yzm <= 0) {
                this.sendAuthCode = true
                clearInterval(auth_timetimer)
              }
            }, 1000)
            console.log('验证码')
            getCode({ phone: this.phoneNumber, actionUserId: '34' }).then((res) => {
              console.log(res)
              showToast(res.message)
              // eslint-disable-next-line eqeqeq
              if (res.status == 1) {
                this.value = res.data.value
              }
            }).catch((err) => {
              console.log(err)
            })
            // eslint-disable-next-line no-empty
          } else {
          }
        } else {
          showToast('手机号格式错误')
        }
      }
    },
    onSubmit (e) {
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line eqeqeq
      if (this.username == '') {
        showToast('请输入姓名')
        // eslint-disable-next-line no-empty, eqeqeq
      } else if (this.phoneNumber == '') {
        showToast('请输入手机号')
        // eslint-disable-next-line eqeqeq
      } else if (this.authCode == '') {
        showToast('请输入验证码')
        // eslint-disable-next-line eqeqeq
      } else if (this.address == '') {
        showToast('请选择所在地区（省/市/区')
        // eslint-disable-next-line eqeqeq
      } else if (this.message == '') {
        showToast('请输入详细地址')
        // eslint-disable-next-line eqeqeq
      } else if (this.phoneNumber.length != 11) {
        showToast('请输入正确的11位手机号')
        // eslint-disable-next-line eqeqeq
      } else {
        // 提交表单
        // eslint-disable-next-line eqeqeq
        console.log(this.authCode)
        console.log(this.value)
        // eslint-disable-next-line eqeqeq, no-empty
        // showToast('提交成功')
        // eslint-disable-next-line eqeqeq
        if (this.authCode == this.value) {
          const data = {
            userName: this.username,
            mobile: this.phoneNumber,
            province: this.addressList[0].text,
            code: this.authCode,
            city: this.addressList[1].text,
            area: this.addressList[2].text,
            detailAddress: this.message,
            promotionType: '1'
          }
          // eslint-disable-next-line no-undef
          submit(data).then((res) => {
            showToast(res.message)
            // eslint-disable-next-line no-undef
            _ks_trace.push({
              event: 'form',
              convertId: 549020,
              cb: function () {
                console.log('Your callback function here!')
              }
            })
          }).catch((err) => {
            showToast(err.message)
          })
        } else {
          showToast('验证码错误')
        }
      }
    },
    // 确认地址
    onConfirm (e) {
      let address = ''
      const array = e.selectedOptions
      address = address + array[0].text + '/' + array[1].text + '/' + array[2].text
      this.address = address
      this.showArea = false
      this.addressList = array
    }
  }
}
</script>
<style>
.van-cell__title {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}

p {
  font-size: 12px;
}

.van-field__label {
  width: 5em !important;
}

.title {
  text-indent: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0;
}

.home {
  width: 100%;
  height: 100%;
}

.checkBox {
  display: flex;
  align-items: center;
  padding: 0px 10px 15px 18px;
  font-size: 12px;
}

.agreement-wrapper {
  height: 300px;
  overflow: scroll;
  background-color: #fff;
  text-indent: 28px;
  line-height: 25px;
  padding: 10px 10px;
}

.cc::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: var(--van-padding-md);
  bottom: 0 !important;
  left: var(--van-padding-md);
  border-bottom: 1px solid var(--van-cell-border-color);
  transform: scaleY(.5);
}

.order-wrapper {
  margin-top: -4px;
  padding: 20px 18px;
  box-sizing: border-box;
}

.van-cell-group--inset {
  margin: 0 !important;
}

.order {
  border-radius: 4px;
  background-color: #fff;
  padding: 4px;
}

.form-name {
  text-align: left;
  display: none;
  padding: 10px 20px;
  font-size: 15px;
  color: #333;
  font-weight: 700
}

.photolist {
  width: 5em;
}

.ss {
  position: relative;
}</style>
